import {
  StandardsBallotsResponse,
  StandardsBallotsVoteModel,
  CommitteeCycleModel,
  NetsuiteBallotData,
  NetsuiteBallotLineData,
  StandardsBallotLineItemModel,
  StandardsBallotsVoteResponse,
  CommitteeCycleVotes,
  ProxyFileUploadResponse,
  StandardsBallotsData,
} from './Models';
import { ProxyService } from './ProxyService';
import { conf, semiProxyBaseUrl } from 'config';
import { SelectOption } from 'components/common/SelectComponent';

export class BallotsService {
  public static getStandardsCommitteeCycles(): Promise<StandardsBallotsData> {
    return ProxyService.unauthenticatedProxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuiteAnonymous,
      'get',
      {
        script: conf.netSuite.standardsBallotsScriptId,
      },
      null
    ).then((response: StandardsBallotsResponse): Promise<StandardsBallotsData> => {
      // Group ballot line items in same committee cycle as ballots.
      const committeeCycles = response.ballotData.map((committeCycle: NetsuiteBallotData): CommitteeCycleModel => {
        // Find the ballot line committe cycle that matches this committee cycle
        const ballotLineCommitteeCycle = response.ballotLineData.find(
          (c: NetsuiteBallotLineData): boolean =>
            c.committee === committeCycle.committee && c.period === committeCycle.period
        );

        return {
          ...committeCycle,
          ballotLineData: ballotLineCommitteeCycle ? ballotLineCommitteeCycle.ballotLineData : [],
        };
      });

      const data: StandardsBallotsData = {
        committeeCycles: committeeCycles,
        votingAnnouncements: response.announcement,
      };

      return Promise.resolve(data);
    });
  }

  public static committeeCyclesToCycleSelectOptions(committeeCycles: CommitteeCycleModel[] | null): SelectOption[] {
    const emptyOption: SelectOption = { name: '', value: '' };

    if (!committeeCycles) {
      return [emptyOption];
    }

    const uniqueCycles = Array.from(new Set(committeeCycles.map((c: CommitteeCycleModel): string => c.period)));

    return [
      emptyOption,
      ...uniqueCycles.map((cycle: string): SelectOption => {
        return { name: cycle, value: cycle };
      }),
    ];
  }

  public static committeeCyclesToCommitteeSelectOptions(committeeCycles: CommitteeCycleModel[] | null): SelectOption[] {
    const emptyOption: SelectOption = { name: '', value: '' };

    if (!committeeCycles) {
      return [emptyOption];
    }

    const uniqueCommittees = Array.from(new Set(committeeCycles.map((c: CommitteeCycleModel): string => c.committee)));

    return [
      emptyOption,
      ...uniqueCommittees.map((committee: string): SelectOption => {
        return { name: committee, value: committee };
      }),
    ];
  }

  public static findLineItemVote(
    lineItem: StandardsBallotLineItemModel,
    votes: StandardsBallotsVoteModel[]
  ): StandardsBallotsVoteModel {
    const existingVote = votes.find(
      (v: StandardsBallotsVoteModel): boolean =>
        v.ballotInternalId === lineItem.ballotInternalId && v.lineItemInternalId === lineItem.lineItemInternalId
    );

    return existingVote
      ? existingVote
      : {
          standardVoteId: '-1',
          ballotInternalId: lineItem.ballotInternalId,
          lineItemInternalId: lineItem.lineItemInternalId,
          ballotTypeId: lineItem.ballotTypeId,
          vote: lineItem.ballotTypeId === '1' ? 'Accept' : 'Approve',
        };
  }

  public static GetCommitteeCycleVotes(): Promise<CommitteeCycleVotes[]> {
    return ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'get',
      {
        script: conf.netSuite.standardsBallotsVotesScriptId,
      },
      null
    ).then((res: StandardsBallotsVoteResponse): Promise<CommitteeCycleVotes[]> => {
      return Promise.resolve(res.data ? res.data : []);
    });
  }

  public static async SaveVotes(votes: StandardsBallotsVoteModel[]): Promise<void> {
    const fileUploadPromises: Array<Promise<string | undefined>> = [];

    const votePayload = votes.map((vote) => {
      const v: StandardsBallotsVoteModel = { ...vote };
      if (v.uploadedFile) {
        fileUploadPromises.push(
          ProxyService.proxyUploadFile(
            semiProxyBaseUrl + conf.proxyEndpoints.fileUpload,
            v.uploadedFile,
            v.ballotInternalId,
            v.lineItemInternalId
          ).then((res: ProxyFileUploadResponse): string => res.url)
        );
        v.fileName = v.uploadedFile.name;
        delete v.uploadedFile;
      } else {
        fileUploadPromises.push(Promise.resolve(v.fileURL));
      }
      return v;
    });

    const values = await Promise.all(fileUploadPromises);
    values.forEach((fileUrl: string | undefined, i: number) => {
      votePayload[i].fileURL = fileUrl;
    });
    const requestBody = {
      voteData: votePayload,
    };
    await ProxyService.proxyRequest(
      semiProxyBaseUrl + conf.proxyEndpoints.semiNetSuite,
      'post',
      { script: conf.netSuite.standardsBallotsVotesScriptId },
      requestBody
    );
  }
}
